import Link from 'next/link'

import { footer } from '../utils/content'

const AppFooter = () => {
  return (
    <div className="px-3 hidden-pdf">
      <footer className="container mx-auto border-t py-12 px-3 border-dark-15 flex items-center justify-between flex-col sm:flex-row">
        <ul className="flex flex-col sm:flex-row  items-center px-3 sm:px-0">
          {footer.items.map((item, index) => (
            <li key={`footer-item-${index}`} className="sm:mr-3 md:mr-6">
              {item.route ? (
                <Link href={item.route}>
                  <a className="text-navy-5 hover:text-navy-3 transition-all hover:underline">
                    {item.text}
                  </a>
                </Link>
              ) : (
                <a
                  href={item.link}
                  target={item.targetBlank ? '_blank' : ''}
                  className="text-navy-5 hover:text-navy-3 transition-all hover:underline">
                  {item.text}
                </a>
              )}
            </li>
          ))}
        </ul>
        <div className="mt-10 sm:mt-0 text-navy-5 font-light">
          &copy; {new Date().getFullYear()} Monicat Data
        </div>
      </footer>
    </div>
  )
}

export default AppFooter
